body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* UTILITIES */

.sticky-col {
  height: min-content;
}

.flex-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-header {
  display: flex;
  justify-content: space-between;
}

.header-image-size {
  height: 75%;
}

@media (min-width: 768px) {
  .sticky-col {
    position: sticky;
    top: 1rem;
  }
}

@media (max-width: 768px) {
  .header-image-size {
    height: 40%;
  }

  .job-header {
    flex-direction: column;
  }
}

.background-pattern {
  background-image: url("/cubes.png");
  background-repeat: repeat;
}

.home-card {
  background-color: white;
  box-shadow: lightgrey -5px 5px 40px 5px;
  padding: 2rem;
  border-radius: 3px;
}
