.contact-form {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contact-form > input,
.contact-form > textarea,
.contact-form > a {
  margin-top: 0.5rem;
}

.contact-form > input:focus,
.contact-form > textarea:focus {
  outline-offset: -2px;
  outline: #5eafcc auto 5px;
}

.contact-form > input,
.contact-form > textarea {
  padding: 10px;
  border: none;
  background-color: #f8f8f8;
  border-radius: 3px;
  width: 100%;
}

.contact-form > a {
  color: #5eafcc;
  border: solid #5eafcc 1px;
  border-radius: 0.25rem;
  width: fit-content;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
}

.contact-form > textarea {
  height: 30vh;
}

.contact-form > a:hover {
  background-color: #5eafcc33;
  text-decoration: none;
}
