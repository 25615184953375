@keyframes wobble {
  0% {
    transform: translateY(1vh);
  }
  25% {
  }
  50% {
    transform: translateY(-5vh);
  }
  75% {
  }
  100% {
    transform: translateY(1vh);
  }
}

.brian-head-wobble {
  animation: wobble 7s ease-in-out 2s infinite normal;
}
