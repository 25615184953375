@import url(https://fonts.googleapis.com/css2?family=Anton&family=Nunito:ital,wght@0,600;0,800;1,400;1,800&family=Open+Sans+Condensed:wght@300&family=Russo+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* UTILITIES */

.sticky-col {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.flex-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-header {
  display: flex;
  justify-content: space-between;
}

.header-image-size {
  height: 75%;
}

@media (min-width: 768px) {
  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    top: 1rem;
  }
}

@media (max-width: 768px) {
  .header-image-size {
    height: 40%;
  }

  .job-header {
    flex-direction: column;
  }
}

.background-pattern {
  background-image: url("/cubes.png");
  background-repeat: repeat;
}

.home-card {
  background-color: white;
  box-shadow: lightgrey -5px 5px 40px 5px;
  padding: 2rem;
  border-radius: 3px;
}

.main-header-font {
  font-family: "Russo One", sans-serif;
  font-size: 100px;
  font-weight: 600;
  color: #5eafcc;
}

.subheader-font {
  font-family: "Nunito", sans-serif;
  font-size: 30px;
  text-shadow: -2px 2px 15px #535353;
}

.no-shadow {
  text-shadow: none;
  box-shadow: none;
}

.paragraph-font {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
}

.job-date {
  font-size: 16px;
}

@media (max-width: 768px) {
  .main-header-font {
    font-size: 35px;
  }

  .subheader-font {
    font-size: 26px;
  }

  .paragraph-font {
    font-size: 18px;
  }

  .job-date {
    font-size: 14px;
    margin-top: 0 !important;
    margin-bottom: 0.25rem !important;
  }
}

@-webkit-keyframes wobble {
  0% {
    transform: translateY(1vh);
  }
  25% {
  }
  50% {
    transform: translateY(-5vh);
  }
  75% {
  }
  100% {
    transform: translateY(1vh);
  }
}

@keyframes wobble {
  0% {
    transform: translateY(1vh);
  }
  25% {
  }
  50% {
    transform: translateY(-5vh);
  }
  75% {
  }
  100% {
    transform: translateY(1vh);
  }
}

.brian-head-wobble {
  -webkit-animation: wobble 7s ease-in-out 2s infinite normal;
          animation: wobble 7s ease-in-out 2s infinite normal;
}

.contact-form {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contact-form > input,
.contact-form > textarea,
.contact-form > a {
  margin-top: 0.5rem;
}

.contact-form > input:focus,
.contact-form > textarea:focus {
  outline-offset: -2px;
  outline: #5eafcc auto 5px;
}

.contact-form > input,
.contact-form > textarea {
  padding: 10px;
  border: none;
  background-color: #f8f8f8;
  border-radius: 3px;
  width: 100%;
}

.contact-form > a {
  color: #5eafcc;
  border: solid #5eafcc 1px;
  border-radius: 0.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
}

.contact-form > textarea {
  height: 30vh;
}

.contact-form > a:hover {
  background-color: #5eafcc33;
  text-decoration: none;
}

