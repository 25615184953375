@import url("https://fonts.googleapis.com/css2?family=Anton&family=Nunito:ital,wght@0,600;0,800;1,400;1,800&family=Open+Sans+Condensed:wght@300&family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

.main-header-font {
  font-family: "Russo One", sans-serif;
  font-size: 100px;
  font-weight: 600;
  color: #5eafcc;
}

.subheader-font {
  font-family: "Nunito", sans-serif;
  font-size: 30px;
  text-shadow: -2px 2px 15px #535353;
}

.no-shadow {
  text-shadow: none;
  box-shadow: none;
}

.paragraph-font {
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
}

.job-date {
  font-size: 16px;
}

@media (max-width: 768px) {
  .main-header-font {
    font-size: 35px;
  }

  .subheader-font {
    font-size: 26px;
  }

  .paragraph-font {
    font-size: 18px;
  }

  .job-date {
    font-size: 14px;
    margin-top: 0 !important;
    margin-bottom: 0.25rem !important;
  }
}
